import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './crisis-response.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns is-centered">
      <div className={`${styles.container}`}>
        <div className={`${styles.contentbox}`}>
          <a
            href="https://www.trustaff.com/apply?utm_source=CrisisResponse"
            target="_blank"
            rel="noreferrer"
          >
            <Img
              fluid={fluid}
              className={styles.aboutImageWrapper}
              alt="Crisis Response Jobs"
            />
          </a>
          <h1 className={styles.gigantor}>Crisis Response Jobs Nationwide</h1>
          <p className={`content`}>
            Nurses and other healthcare professionals needed to provide urgent
            care during continued staffing shortages and vaccination programs as
            hot spots arise throughout different parts of the country. Are you
            ready to help?
          </p>
          <p className={`content`}>
            • <strong>Crisis pay rates</strong>
            <br />
            • ASAP start dates
            <br />
            • Limited contracts 4-13 weeks
            <br />• Travel &amp; housing assistance
          </p>
          <p className={`content`}>
            New positions are moving fast and opening daily. Select states have
            issued special provisions to assist with licensing during the
            shortage. If you are not licensed in a state where you would like to
            travel, Trustaff will not only assist with expediting your license
            but will{' '}
            <Link to="/travel-nursing/license-reimbursement/">
              reimburse your costs
            </Link>
            .
          </p>
          <p className={`content ${styles.subheader}`}>
            As new opportunities arise, those with a <u>complete application</u>{' '}
            on file with Trustaff will be submitted first. Apply now to ensure
            you're first in line!
          </p>
          <Link
            to="/apply?utm_source=CrisisResponseJobs/"
            className="button is-primary"
          >
            Apply now
          </Link>
          <div className={`section ${styles.icons}`}></div>
        </div>
      </div>
    </div>
  );
};
