import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import styles from './crisis-response.module.scss';
import CrisisResponse from '../components/landing-page/crisis-response';
import { Helmet } from 'react-helmet';
import { GatsbyImg } from '../services/img-location/img-location-service';

export default ({ data }) => {
  const gatsbyImg = new GatsbyImg('crisis-response', data);

  return (
    <>
      <Helmet>
        <title>Crisis response jobs - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Nurses and other healthcare professionals needed for immediate job openings nationwide to provide care during coronavirus staffing shortages."
        />
        <meta property="og:title" content="Crisis Response Jobs" />
        <meta
          property="og:description"
          content="Nurses and other healthcare professionals needed for immediate job openings nationwide to provide care during coronavirus staffing shortages."
        />
        <meta property="og:image" content={gatsbyImg.ogImgUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/crisis-response/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <CrisisResponse fluid={gatsbyImg.fluidImg} />
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^landing-page/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
